import React from "react"
import Layout from "../components/layout"
import * as styles from "./cookie_policy.module.css"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ColoredSeparator from "../components/colored_sep"


const sidep_link_style = {
	color: "#F15B5B",
	fontWeight: "bold",
	textDecoration: "none",
}


const MainP = () => {
	return (
		<>
		<div style={{marginTop:"50px"}}>
			<h1>Privacy Policy</h1>
			<p>
				This Privacy Policy describes how your personal information is collected, used, and shared when you visit
				<Link to="/" style={sidep_link_style}> https://www.samanthasantonidesign.com/</Link> (the “Site”).
		    </p>

			<h3>PERSONAL INFORMATION WE COLLECT</h3>

			<p>
				When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
				Additionally, as you browse the Site, we collect information about the individual web pages,
				what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
		    </p>

			<p>
				When you contact us via email,
				we collect your address as well as any contact information present in the missive.
				We refer to this voluntarily sent information as “User Information”.
			</p>

			<p>
				We collect Device Information using the following technologies:
			</p>

			<p>
				- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier.
				For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>http://www.allaboutcookies.org</a>.
			</p>

			<p>
				When we talk about “Personal Information” in this Privacy Policy, we are talking about both Device Information and User Information.
			</p>

			<h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>

			<p>
				We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
				<br></br><br></br>
				We use the User Information solely to address and reply to your requests.
			</p>

			<h3>SHARING YOUR PERSONAL INFORMATION</h3>
			<p>
				We share your Personal Information with third parties to help us use your Personal Information,
				as described above. We use Google Analytics to help us understand how our customers use the Site--
				you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>https://www.google.com/intl/en/policies/privacy/</a>.
				You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>https://tools.google.com/dlpage/gaoptout</a>.
				<br />
				Finally, we may also share your Personal Information to comply with applicable laws
				and regulations, to respond to a subpoena, search warrant or
				other lawful request for information we receive, or to otherwise protect our rights.
			</p>

			<h3>BEHAVIOURAL ADVERTISING</h3>
			<p>
				As described above, we use your Personal Information to provide you with targeted
				advertisements or marketing communications we believe may be of interest to you.
				For more information about how targeted advertising works,
				you can visit the Network Advertising Initiative’s (“NAI”) educational page
				at <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
					<br />
				You can opt out of targeted advertising by:
				<br />
				GOOGLE - <a href="https://www.google.com/settings/ads/anonymous" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>https://www.google.com/settings/ads/anonymous</a>.
			</p>

			<p>
				Additionally, you can opt out of some of these services by visiting the Digital Advertising
				Alliance’s opt-out portal at: <a href="http://optout.aboutads.info/" target="_blank" rel="noopener noreferrer" style={sidep_link_style}>http://optout.aboutads.info/</a>.
			</p>

			<h3>DO NOT TRACK</h3>
			<p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

			<h3>YOUR RIGHTS</h3>
			<p> If you are a European resident, you have the right to access personal information
				we hold about you and to ask that your personal information be corrected, updated, or deleted.
				If you would like to exercise this right, please contact us through the contact information below.
				Additionally, if you are a European resident we note that we are processing your information
				in order to fulfill contracts we might have with you (for example if you make an order through the Site),
				or otherwise to pursue our legitimate business interests listed above.
				Additionally, please note that your information will be transferred outside of Europe,
				including to Canada and the United States.
			</p>

			<h3>DATA RETENTION</h3>
			<p>
				When you contact us, we will maintain your User Information for our records unless and until
				you ask us to delete this information.
			</p>

			<h3>MINORS</h3>
			<p>
				The Site is not intended for individuals under the age of 15.
			</p>

			<h3>CHANGES</h3>
			<p>
				We may update this privacy policy from time to time in order to reflect,
				for example, changes to our practices or for other operational, legal or regulatory reasons.
			</p>

			<h3>CONTACT US</h3>
			<p>
				For more information about our privacy practices, if you have questions,
				or if you would like to make a complaint, please contact us by e-mail at
				<a href="mailto:2sdesign.santoni@gmail.com" target="_blank" rel="noopener noreferrer" style={sidep_link_style}> 2sdesign.santoni@gmail.com</a>
			</p>
		</div>
		</>

	)
}


const PrivacyPolicyPage = () => {
	React.useEffect( () => {
		if( typeof window !== "undefined" )
			window.scrollTo( 0, 0 )
	}, [])

	return (
		<Layout>
			<Seo title="Info" subTitle="Privacy Policy"/>
			<div className={styles.pageWrapper}>
				<div className={styles.grid}>
					<div className={styles.cellitem}>
						{<MainP/>}
					</div>

					<div className={styles.cellitem + " " + styles.backLink_wrap}>
						<Link to="/" className={styles.backLink}>BACK TO HOME PAGE</Link>
						<ColoredSeparator color="black" />
					</div>
				</div>
			</div>

		</Layout>
	)
}


export default PrivacyPolicyPage
